<template>

    <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <div class="rpanel-title text-capitalize">
            <div class="flex">
                 <a href="Javascript:void(0)" v-if="view == 3"  @click="view = 2,  scrollToTop()" style="width: 100px" :class="{ 'w-100' : view > 1 }">
                    <i class="pi pi-chevron-left"></i>Back
                </a>
                <a href="Javascript:void(0)" v-if="view == 4"  @click="view = 2,  scrollToTop()" style="width: 100px" :class="{ 'w-100' : view > 1 }">
                    <i class="pi pi-chevron-left"></i>Back
                </a>
                <a href="Javascript:void(0)" v-if="view == 2"  @click="view = 1,  scrollToTop()" style="width: 100px" :class="{ 'w-100' : view > 1 }">
                    <i class="pi pi-chevron-left"></i>Back
                </a>

                <div v-if="view == 1" class="w-100">
                    <p>Report</p>
                    <small>{{ selectedClass.name }}</small>
                </div>
                <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
            </div>
        </div>
        <div class="drawer-content" id="scrollTop">
            <section  class="p-10  pt-0 mt-0" style="height: 7000px;">
                      <vue3-html2pdf
                        :show-layout="true"
                        :float-layout="false"
                        :enable-download="false"
                        :preview-modal="true"
                        filename="class-report"
                        :manual-pagination="true"
                        pdf-orientation="portrait"
                        pdf-content-width="100%"
                        ref="html2Pdf"
                        :html-to-pdf-options="pdfOptions">
                   <template v-slot:pdf-content>
                       <section>
                       <nav aria-label="breadcrumb" v-if="view  >= 0 && view < 2" class="pb-3">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selectedClass.name }}</a></li>
                                <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ active_term }}</a></li>
                                <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selected_week }}</a></li>
                                <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ selected_survey.name }}</a></li>
                            </ol>
                        </nav>
                       <template  v-if="view == 1">
                            <div class="form-group">
                                <label>Select Survey <span class="text-danger">*</span></label>
                                <select class="form-control" name="class"  v-model="selected_survey">
                                    <option value="">Select</option>
                                    <option :value="default_survey">{{ default_survey.name}}</option>
                                </select>
                            </div>
                            <div class="form-group pt-3"  v-show="1 == 2">
                                <label>Select Class <span class="text-danger">*</span></label>
                                <select class="form-control" name="class"  v-model="selectedClass">
                                    <option value="">Select</option>
                                    <option v-for="(item, i ) in class_list" :key="i" :value="item">{{item.name}}</option>
                                </select>   
                            </div>
                            <div class="row " v-show="1 == 2">
                                <div class="col-sm-6 col-12">
                                    <div class="form-group" v-if="selected_survey || !(selectedClass.length  === 0)">
                                        <label>Select Term <span class="text-danger">*</span></label>
                                        <select class="form-control" name="class"  v-model="active_term">
                                            <option value="">Select</option>
                                            <option v-for="(item, i ) in defaul_term_list" :key="i" :value="item.text">{{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12">
                                    <div class="form-group" v-if="selected_survey || !(selectedClass.length  === 0)">
                                        <label>Select Week <span class="text-danger">*</span></label>
                                        <select class="form-control" name="class"  v-model="selected_week">
                                            <option value="">Select</option>
                                            <option v-for="(item, i ) in week_list" :key="i" :value="item.week_no">{{ item.week_no }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            </template>
                            <template v-if="view == 4">
                            <section v-for="(item, i) in survey_report.question_list" :key="i" class="box-shadow mb-5 pt-5 pb-5 p-0" style="box-shadow: none !important; border: 0px !important">
                                    <h4 class="pl-6 fs-14 pr-6 pb-4">{{i + 1}}. {{item.question}}</h4>

                                    <section style="background: #FF5C5C" class="p-10" v-if="i == 0">
                                        <p class="text-center pb-0 mb-0  fs-14 text-white">Student most likely missing</p>
                                        <p class="text-center pb-0 mb-0  fs-14  text-white">connection in the class</p>

                                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="i == 0">
                                            <section class="mb-1 pb-1 cursor-pointer" v-for="(student, x) in survey_report.lowest" :key="x" v-on:click="viewStudents(student, x)">
                                                <section class="d-flex student-card">
                                                    <span class="pl-5 pt-3 pb-2 text-catapilize flex w-100">
                                                        <p class="pb-0 mb-0 text-capitalize fs-13 w-100">{{student.name}}</p>
                                                        <p class="pr-3">{{ student.count }}</p>
                                                    </span>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                    <section  class="p-10" v-if="i == 0">
                                        <p class="fs-16 text-center fw-600  pb-0 mb-0 ">Results</p>

                                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="i == 0">
                                            <section class="mb-1 pb-1 cursor-pointer" v-for="(student, x) in survey_report.student_list" :key="x"  v-on:click="viewStudents(student, x)">
                                                <section class="d-flex student-cardv2">
                                                    
                                                    <span class="pl-5 pt-3 pb-2 flex w-100">
                                                        <p class="pb-0 mb-0 fs-13   text-capitalize  w-100">{{student.name}}</p>
                                                        <p class="pr-3 pb-0 mb-0">{{ student.count }}</p>
                                                    </span>
                                                </section>
                                            </section>
                                        </section>
                                    </section>
                                    <section  class="p-10" v-if="i == 1">
                                        <p class="fs-16 text-center fw-600  pb-0 mb-0 ">Results</p>

                                        <section class="pl-5 pr-5 pt-5 pb-5" v-if="i == 1">
                                            <section class="mb-1 pb-1 cursor-pointer" v-for="(opt, x) in ['Yes', 'No']" :key="x" @click="viewStudentsQuestion2(x + 1)">
                                                <section class="d-flex student-cardv2">
                                                    <h4 class="pl-5 pt-3 flex w-100">
                                                        <p class="pb-0 mb-0 fs-13 w-100">{{ opt }}</p>
                                                        <p class="pr-3 pb-0 mb-0 fs-13" v-if="opt == 'Yes'">{{ survey_report.yes }}</p>
                                                        <p class="pr-3 pb-0 mb-0 fs-13" v-else>{{ survey_report.no }}</p>
                                                    </h4>
                                                </section>
                                            </section>
                                        </section>
                                        <ColumnChart :is_horizontal="false"  :refresh_column_chart="1" :series="survey_report.seriesTwo" :categories="survey_report.categoriesTwo" ></ColumnChart>
                                        <div class="html2pdf__page-break"/>
                                    </section>
                                    <section  class="p-10" v-if="i == 2">
                                        <p class="fs-16 text-center fw-600  pb-0 mb-0 ">Results</p>
                                        <section class="pl-5 pr-5 pt-5 pb-" v-if="i == 2">
                                            <section class="mb-1 pb-1 cursor-pointer" v-for="(opt, x) in survey_report.question_three" :key="x" @click="viewStudentsQuestion3(opt)">
                                                <section class="d-flex student-cardv2">
                                                    <h4 class="pl-5 pt-3 flex w-100">
                                                        <p class="pb-0 mb-0 fs-13 w-100">{{ opt.options }}</p>
                                                        <p class="pr-3 pb-0 mb-0 fs-13">{{ opt.count }}</p>
                                                    </h4>
                                                </section>
                                            </section>
                                        </section>
                                        <ColumnChart :is_horizontal="false"  :refresh_column_chart="2" :series="survey_report.seriesThree" :categories="survey_report.categoriesThree" ></ColumnChart>
                                        <div class="html2pdf__page-break"></div>                        
                                    </section>
                            </section>
                        </template>
                        <div class="html2pdf__page-break"></div>                        
                       </section>
                        </template>
                    </vue3-html2pdf>
                        <template v-if="view == 3 && !survey_student_loading">
                            <h1 class="text-center pt-10 pb-0 mb-0" color="tertiary">{{ survey_student_list.length }} </h1>
                            <p v-if="question_index == 0" class="text-center pt-0 mt-0 fs-14  pb-10">Vote for {{ selected_student.fname }} {{ selected_student.lname }}</p>
                            <p v-if="question_index == 1" class="text-center pt-0 mt-0 fs-14  pb-10">Vote for {{ option_type }} </p>
                            <p v-if="question_index == 2" class="text-center pt-0 mt-0 fs-14  pb-10">Vote for {{ option_type }} </p>


                            <section class="pl-5 pr-5 pt-5 pb-5">
                                <div class="mb-1 pb-4" v-for="(student, x) in survey_student_list" :key="x">
                                    <div class="d-flex student-cardv2 pb-3">
                                       <!--     <Avatar :image="`${web_url}${student.thumbnail}`" class="p-mr-2 ml-4" size="large" shape="circle" /> -->
                                        <h4 class="pl-5 pt-3 flex w-100 fs-13">
                                            <p class="pb-0 mb-0 w-100 fs-13">{{student.name}}</p>
                                        </h4>
                                    </div>
                                </div>
                            </section>
                        </template>
                        
                    <section v-if="view == 2" >
                                <vue3-html2pdf
                                        :show-layout="true"
                                        :float-layout="false"
                                        :enable-download="false"
                                        :preview-modal="true"
                                        filename="class-report-breakdown"   
                                        :manual-pagination="true"
                                        pdf-orientation="portrait"
                                        pdf-content-width="100%"
                                        ref="html2Pdf"
                                        :html-to-pdf-options="pdfOptions">
                                <template v-slot:pdf-content>
                                    <section>
                                        <ClassReportBreakdown :active_term="active_term"
                                                    :selected_week="selected_week"
                                                    :survey_report="survey_report"
                                                    :selectedClass="selectedClass"
                                                    :selectedYear="selectedYear"
                                                    :selected_survey="selected_survey"></ClassReportBreakdown>
                                    </section>
                                </template>
                            </vue3-html2pdf>
                    </section>
            </section>

        </div>
        <div class="r-panel-footer drawer-footer--v2"  >
                <div class="r-panel-footer-content">
                    <!-- 
                    <button v-if="view == 2" type="submit" :disabled="report_loading"  @click="viewBreakdown"      class="btn btn-sm btn-success save mr-2 pull-left float-left">
                        <span>  View Breakdown</span>
                    </button>
                    -->
                    <button v-if="view == 1" type="submit" :disabled="report_loading"  @click="viewReport"      class="btn btn-sm btn-info save mr-2">
                        <span>  View Report</span>
                    </button>
                    <button v-if="view == 2" type="submit" :disabled="report_loading"  @click="generateReport"      class="btn btn-sm btn-primary save mr-2">
                        <span>  Print Report</span>
                    </button>
                    <button v-if="view == 4" type="submit" :disabled="report_loading"  @click="generateReport"      class="btn btn-sm btn-primary save mr-2">
                        <span>  Print Report</span>
                    </button>
                     <button v-if="view == 2 || view == 4" type="submit" :disabled="report_loading"  @click="dialog = true, selectedEmail = []"      class="btn btn-sm btn-info save mr-2">
                        <span>  Share Report</span>
                    </button>
                </div>
        </div>
    </Sidebar>
    <Dialog header="Add class survey report recipient" v-model:visible="dialog"  :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '40vw'}"  :draggable="false" :modal="true">
        <div class="p-5">
            <label>Press Return/Enter after typing new email address</label>
            <VueMultiselect v-model="selectedEmail" tag-placeholder="Add or type email address" placeholder="Add or type email address" label="name" track-by="name"
            :options="optionsEmail" :multiple="true" :taggable="true" @tag="addTag"
            :hide-selected="true"></VueMultiselect>

        </div>

        <template #footer>
            <div class="p-5">
                <button type="submit" :disabled="share_status"  @click="shareReport"      class="btn btn-md btn-info save mr-2">
                    <span> </span>
                    <span v-if="!share_status"> Email Report</span>
                    <span v-else> <i class="fas fa-spinner fa-spinner"></i> Sending....</span>

                </button>
            </div>
        </template>
    </Dialog>
</template>
<script>
import { defineComponent, ref, watch, computed, inject} from 'vue'
import Dialog from 'primevue/dialog';
import { useStore  } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm"   
import Sidebar from 'primevue/sidebar';
import MultiSelect from 'primevue/multiselect';

import  SurveyGetDefault  from '@/composables/survey/SurveyGetDefault'
import  TermsGetList  from '@/composables/terms/TermsGetList'
import  SurveyGetReportByTermAndWeekNo   from '@/composables/survey/SurveyGetReportByTermAndWeekNo'
import Avatar from 'primevue/avatar';
import  SurveyGetStudentByAnswer   from '@/composables/survey/SurveyGetStudentByAnswer'
import  SurveyShareReport   from '@/composables/survey/SurveyShareReport'


import ColumnChart from '@/components/charts/ColumnChart.vue'
import Vue3Html2pdf from 'vue3-html2pdf'
import { ElSelectV2 }   from  'element-plus'
import ClassReportBreakdown from '../class/ClassReportBreakdown.vue';
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
    props: {
        class_report_watcher: { type: Number, default: 1 },
        selected_class: { default: []},
        source: { default: 'class', type: String },
        defaultSurvey: { default: [] },
        class_list: { default: [] },
        selectedTerm: { default: '' },
        selectedWeek: { default: '' },
        selectedYear: { default: '' }
    },
    components: { Sidebar, ColumnChart, Vue3Html2pdf, Dialog, ClassReportBreakdown, VueMultiselect },
    setup(props) {
        const { http, constants,  store, toast } = inject('App') 

        const { sloading,  default_survey, student_list,  getDefaultSurvey } = SurveyGetDefault(http, constants)
        const { term_loading,  term_list, getTermList } = TermsGetList(http, constants)
        const { report_loading,  survey_report, getSurveyReportByTermAndWeekNo } = SurveyGetReportByTermAndWeekNo(http, constants)
        const { survey_student_loading,  survey_student_list, getSurveyStudentListByAnswer } = SurveyGetStudentByAnswer(http, constants)
        const { share_status,  shareSurveyReport } = SurveyShareReport(http, constants, toast)

        let user =  computed(() => store.getters['UserModule/GET_USER'])

        const active_term = ref(props.selectedTerm)
        const defaul_term_list = ref([
            {text: 'Term 1', value: 'Term 1'},
            {text: 'Term 2', value: 'Term 2'},
            {text: 'Term 3', value: 'Term 3'},
            {text: 'Term 4', value: 'Term 4'}
        ]);
        const pdfOptions = ref(
          {
            margin: 15,
            image: {
                type: 'jpeg',
                quality: .95,
            },
          }
        )

        let display = ref(false)
        let week_list = ref([])
        const selected_week = ref(props.selectedWeek)
        const selected_survey = ref('')
        const selectedClass = ref(props.selected_class)
        const view = ref(1)
        const question_index = ref(-1)
        const option_type = ref('Yes')
        const selected_student = ref([])
        const html2Pdf = ref()
        const dialog = ref(false)

        const  selectedEmail = ref([{ name: user.value.email}])
        const optionsEmail = ref([ { name: user.value.email}])

        function addTag (newTag) {
            const tag = {
                name: newTag,
            }
            selectedEmail.value.push(tag)
            optionsEmail.value.push(tag)
        }


        function setWeeks(term) {
            let arr = []
            week_list.value = []

            for (let i = 0; i < term_list.value.length; i++) {
                let element = term_list.value[i]
                if (element.term == term) {
                        arr.push(element)
                }
            }
            week_list.value = arr
        }

        function viewReport() {
            if (active_term.value != '' && selected_week.value != '') {
                view.value = 2
                getSurveyReportByTermAndWeekNo(selectedClass.value.id, active_term.value, selected_week.value, props.selectedYear)
            } else 
                toast.add({severity:'error', summary: 'Error', detail:'Please select all fields', life: 3000});
        }

        function viewStudentsQuestion2(c) {
            question_index.value = 1
            view.value = 3
            if (c == 1)
                option_type.value = 'Yes'
            else option_type.value = 'No'

            getSurveyStudentListByAnswer(selectedClass.value.id, active_term.value, selected_week.value, c, 2)
            scrollToTop()
        }

        function viewStudentsQuestion3(item) {
            question_index.value = 2
            view.value = 3
            option_type.value = item.options
            getSurveyStudentListByAnswer(selectedClass.value.id, active_term.value, selected_week.value, item.id, item.question_id)
            scrollToTop()
        }

        function viewStudents(s) {
            view.value = 3
            question_index.value = 0
            selected_student.value = s
            getSurveyStudentListByAnswer(selectedClass.value.id, active_term.value, selected_week.value, s.id, 1)
            scrollToTop()
        }

        function generateReport () {
            html2Pdf.value.generatePdf()
        }
        function scrollToTop() {
             document.getElementById('scrollTop').scroll({top:0,behavior:'smooth'});
        }
       
        function shareReport() {
            shareSurveyReport(selectedClass.value.id, active_term.value, selected_week.value, selectedEmail.value?.map(g => { return g.name}).join(','))
        }

        function viewBreakdown() {
            view.value = 4
            scrollToTop()
        }
        
        watch(sloading, (n, o) => {
            selected_survey.value = default_survey.value
        })

        watch(active_term, (n, o) => {
            setWeeks(active_term.value)
        });
        
        watch(selectedClass, (n, o) => {
            getTermList(selectedClass.value.id, props.selectedYear)
        });

        watch(share_status, (n, o) => {
             if (share_status.value == false)
                dialog.value = false
        });

        watch(()=>props.class_report_watcher, (n) => {
            display.value = true
            selectedClass.value = props.selected_class

            getDefaultSurvey(0, selectedClass.value.id)
            getTermList(selectedClass.value.id, props.selectedYear)
            selected_survey.value = ''
            view.value = 1

           active_term.value = props.selectedTerm
           selected_week.value = props.selectedWeek

        })

        return {
            display, default_survey, term_list, defaul_term_list, active_term,
            week_list, viewReport, selected_week, report_loading, selected_survey,
            survey_report,  view, generateReport, html2Pdf,
            web_url:  `${process.env.VUE_APP_AWS_URL}`,
            viewStudentsQuestion2, viewStudentsQuestion3, pdfOptions,
            question_index, option_type, survey_student_loading, survey_student_list, viewStudents, selected_student,
            selectedClass, scrollToTop, shareReport, dialog, share_status, selectedEmail, optionsEmail, viewBreakdown,
            addTag

        }
    },
})
</script>

<style lang="scss">


::v-deep(.multiselect-custom) {
    .p-multiselect-label:not(.p-placeholder) {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }

    .country-item-value {
        padding: .25rem .5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: .5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);

        img.flag {
            width: 17px;
        }
    }
}
.multiselect__tag {
    background: #8950fc !important;
}
.multiselect__tag-icon:after {
    color: #fff !important;
}
.multiselect__option--highlight {
     background: #8950fc !important;
}
.multiselect__option--highlight::after {
     background: #8950fc !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
