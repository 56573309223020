<template>
    <div>
        <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                <div class="d-flex align-items-center flex-wrap mr-1">
                    <div class="d-flex align-items-baseline flex-wrap mr-5">
                        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 pl-0 font-size-sm">
                            <li class="breadcrumb-item">
                                {{term_list.length}} Total
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="card-toolbar card-actions">
                        <a href="Javascript:void(0)" @click="addTerm" class="btn font-weight-bolder add-action   add-button-margin">
                            <i class="fas fa-plus icon-md"></i>Add Term
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column-fluid">
            <div class="container ">
                <div class="card-header  p-0 pt-0 border-0 bg-none">
                    <h3 class="card-title  u-flex">
                        <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                            <SelectButton v-model="selectedTerm" :options="viewOptions" ></SelectButton>
                        </span>
                        <div class="p-d-flex p-jc-between me-2 d-flex searchbar-width">
                            <div class="form-group mr-3">
                                <select class="form-control" name="year" v-model="activeYear">
                                    <option :value="2022" >2022</option>
                                    <option :value="2023" selected>2023</option>
                                    <option :value="2024">2024</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin-top: 2px;">
                                <Dropdown v-model="selectedClassID" :options="class_list" optionLabel="name" optionValue="id" placeholder="Select a class" />
                            </div>
                        </div>
                    </h3>
                </div>
                <div class="card card-custom">
                    <div class="card-body" id="kt_content">
                        <DataTable  class="p-datatable-lg" ref="dt"  :value="weekList"
                            dataKey="id"  filterDisplay="menu"  :loading="term_loading"
                            :paginator="true" :rows="20"
                            :rowsPerPageOptions="[6, 10,20,50, 100, 1000]"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <template #header>
                                </template>
                                <Column field="term" header="Week" :sortable="true"></Column>
                                <Column field="week_no" header="Week" :sortable="true"></Column>
                                <Column field="id" header="">
                                    <template #body="slotProps">
                                        <a  href="Javascript:void(0)" v-tooltip.top="'Report'" @click="viewReport(slotProps)" class="mr-2 pull-right float-right" data-toggle="dropdown">
                                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero"/>
                                                        <path d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>

                                        <a  href="Javascript:void(0)" v-tooltip.top="'Delete'" @click="deletePrompt(slotProps)" class="mr-2 pull-right float-right" data-toggle="dropdown">
                                            <span class="svg-icon svg-icon-primary svg-icon-2x">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24"/>
                                                        <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                                                        <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                        
                                    </template>
                                </Column>
                                <template #loading>
                                    Loading customers data. Please wait.
                                </template>
                                <template #empty>
                                    No results found.
                                </template>
                            </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <ConfirmDialog></ConfirmDialog>
   <TermAdd  :selected_class="selectedClass" :term_add_watcher="term_add_watcher" :location="`class`" :selected_term="selectedTerm"></TermAdd>
   <TermReport :selectedYear="selectedWeek.year" :class_report_watcher="class_report_watcher" :class_list="class_list"  :source="`term`" :selected_class="selectedClass" :selectedTerm="selectedTerm" :selectedWeek="selectedWeek.week_no"></TermReport>

</template>
<script>
import { defineComponent, ref, watch, onMounted, provide   , inject } from 'vue'
import  TermsGetList  from '@/composables/terms/TermsGetList'
import  ClassGetList  from '@/composables/class/ClassGetList'
import  TermsDeleteWeek  from '@/composables/terms/TermsDeleteWeek'


import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from "primevue/useconfirm"    
import TermAdd from './TermAdd.vue'
import TermReport from './TermsReport.vue'

import SelectButton from 'primevue/selectbutton';
import  YearGetList  from '@/composables/year/YearGetList'

export default defineComponent({
    components: {
        Dropdown, DataTable, Column, ConfirmDialog, TermAdd, SelectButton, TermReport
    },
    setup() {
        const { http, store, constants, toast } = inject('App')
        const confirm = useConfirm()
        
        const { class_loading, class_list, getClassList } = ClassGetList(http, constants, store)
        const  { term_loading, term_list, getTermList } = TermsGetList(http, constants, store)
        const  { delete_status, deleteWeek } = TermsDeleteWeek(http, constants, toast)
        const { yearList, getList } = YearGetList(http, constants, store)

        const selectedClassID = ref([])
        const activeYear = ref(store.getters['YearsModule/GET_ACTIVE_YEAR'])
        const selectedWeek = ref([])
        const selectedClass = ref([])
        const term_add_watcher = ref(1)
        const selectedTerm = ref('Term 1')
        const viewOptions = ref(['Term 1', 'Term 2', 'Term 3', 'Term 4']);
        const class_report_watcher = ref(1)

        function addTerm() {
            term_add_watcher.value++
        }

        const weekList = ref([])
        function deletePrompt(q) {
            selectedWeek.value = q.data
            confirm.require({
                message: `Are you sure you want to delete this week.`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Continue Delete',
                accept: () => {
                    deleteNow(q)
                },
                reject: () => {
                }
            })
            return {deletePrompt}
        }

        function selectTerm(term) {
            let arr = []
            weekList.value = []

            for (let i = 0; i < term_list.value.length; i++) {
                let element = term_list.value[i]
                if (element.term == term) {
                    arr.push(element)
                }
            }
            weekList.value = arr
        }
        
        async function deleteNow(q) {
            await deleteWeek(q.data.id, selectedClassID.value)
            getTermList(q.data.class_id, activeYear.value)
        }

        const viewReport = ((q) => {
            selectedWeek.value = q.data
            class_report_watcher.value++
        })

        watch(selectedTerm, (n, o) => {
            selectTerm(selectedTerm.value)
        });

        watch(term_loading, (n, o) => {
            if (term_loading.value == false)
             selectTerm(selectedTerm.value)
        });

        watch(selectedClassID, (n, o) => {
            let index = class_list.value.findIndex(obj => obj.id === selectedClassID.value)
            
           // fruits.findIndex(fruit => fruit === "blueberries");
            selectedClass.value = class_list.value[index]

            getTermList(selectedClassID.value, activeYear.value)
        });

        watch(activeYear, (n, o) => {
            getTermList(selectedClassID.value, activeYear.value)
        });

        onMounted(async() => {
            await getList()
            activeYear.value = store.getters['YearsModule/GET_ACTIVE_YEAR']

            await getClassList()
            selectedClassID.value = class_list.value[0].id
            selectedClass.value = class_list.value[0]

            await getTermList(selectedClassID.value, activeYear.value)

            selectTerm(selectedTerm.value)
        })

        provide('StudentsSurvey', { getTermList })

        return {
            term_list,
            selectedClass,
            selectedClassID,
            class_list,
            deletePrompt,
            term_loading,
            term_add_watcher,
            selectedTerm,
            viewOptions,
            addTerm,
            weekList,
            class_report_watcher,
            viewReport,
            selectedWeek,
            activeYear
        }
    },
})
</script>
